// fonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@100,400;500;600;700&display=swap');

// variables
@import "~bulma/sass/utilities/initial-variables";
@import "variables";

// dependencies
@import "~bulma/bulma";
@import "~bulma-extensions/bulma-pageloader/src/sass";

$min-content-height: calc(100vh - #{$navbar-height});

html {
  width: 100%;
  height: 100%;
  font-size: 16px;
}

body, #root {
  width: 100vw;
  min-height: $min-content-height;
  background-color: $background;
}

.navbar-brand .navbar-item {
  &.sa {
    font-weight: 600;
    text-transform: uppercase;
  }
}

main {
  width: 100vw;
  min-height: $min-content-height;

  &.centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

$layout-subtitle-height: 3rem;

.layout-subtitle {
  display: flex;
  background-color: $dark;
  padding: 0 $size-7;
  height: $layout-subtitle-height;
  align-items: center;
  justify-content: space-between;
}

.ready-to-scan {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(#{$min-content-height} - #{$layout-subtitle-height});
  padding: $size-7;
  text-align: center;
}

.logout-page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: $min-content-height;
  background: $primary;
  color: $white;
}

.fullscreen-scanner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: $min-content-height;
  z-index: 10000;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  align-items: flex-end;
  justify-content: center;

  &.is-inactive {
    display: none;
  }

  .scanner {
    width: 100%;
    background-color: $white;
    padding-top: $size-5;
    padding-bottom: $size-1;
    border-top-left-radius: $size-3;
    border-top-right-radius: $size-3;
    text-align: center;
  }
}

.swiper {
  width: 100%;
  height: $min-content-height;
  background-color: $primary;

  .swiper-wrapper {
    width: 100%;
    height: $min-content-height;

    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: $white;
  }

  .swiper-pagination {
    color: $white;
    text-shadow: $text 0 0 5px;
    font-weight: 600;
    font-size: $size-7;

    .swiper-pagination-bullet-active {
      background-color: $white;
    }
  }
}
